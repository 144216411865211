import React from 'react';
import  Map  from './maps'
import _ from 'lodash';

import {htmlToReact, markdownify} from '../utils';


  const locations = [
    { lat: 15.921424, lng: 77.988251, name: "Sahasra MEGAA Factory - Door no: 12/78, Sy No: 149/E/2,Pulur,Undavalli Mandal,Jogulamba, Gadawal District,Telangana" }
  ];
    

    
const addMarkers = links => map => {
    links.forEach((link, index) => {
      var position = new window.google.maps.LatLng(link.lat, link.lng);
      const marker = new window.google.maps.Marker({
        map,
        position: position,
        label: `S`,
        title: link.name,
        id: index + 1
      });
      const infowindow = new window.google.maps.InfoWindow({
        content: link.name
      });
      marker.addListener(`click`, () => {
        infowindow.open(map, marker);
      });
    });
  };    

const mapProps = {
    options: { center: { lat: 15.921424, lng: 77.988251 }, zoom :12 },
    onMount: addMarkers(locations)
  };
    
    
const markerClickHandler = (id, marker) => {
    alert("clicked");
    window.google.maps.event.trigger(id, "click");
  };
    
export default class SectionContact extends React.Component {
    render() {
        let section = _.get(this.props, 'section');
        return (
            <section id={_.get(section, 'section_id')} className={'block contact-block bg-' + _.get(section, 'background') + ' outer'}>
              <div className="block-header inner-small">
                {_.get(section, 'title') && 
                <h2 className="block-title">{_.get(section, 'title')}</h2>
                }
                {_.get(section, 'subtitle') && 
                <p className="block-subtitle">
                  {htmlToReact(_.get(section, 'subtitle'))}
                </p>
                }
              </div>
              <div className="block-content inner-medium">
                {markdownify(_.get(section, 'content'))}
                <form name="contactForm" method="POST" netlifyHoneypot="bot-field" data-netlify="true" id="contact-form"
                  className="contact-form">
                  <p className="screen-reader-text">
                    <label>Don't fill this out if you're human : <input name="bot-field" /></label>
                  </p>
                  <p className="form-row">
                    <label className="form-label">Name</label>
                    <input type="text" name="name" className="form-input"/>
                  </p>
                  <p className="form-row">
                    <label className="form-label">Email address</label>
                    <input type="email" name="email" className="form-input"/>
                  </p>
                  <p className="form-row">
                    <label className="form-label">Message</label>
                    <textarea name="message" className="form-textarea" rows="7" />
                  </p>
                  <input type="hidden" name="form-name" value="contactForm" />
                  <p className="form-row form-submit">
                    <button type="submit" className="button">Send Message</button>
                  </p>
                </form>
              </div>
		<h1> Call us : +91-9490601966 </h1>
		<h2> Email us : sahasra@megablocks.in </h2>
                <h1>Come Visit us</h1>
                <Map {...mapProps} />
            </section>
        );
    }
}
